import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PageTitle from '../../components/main/PageTitle';
import Header from '../../components/header/Header';
import Footer from "../../components/footer/Footer";
import CrossImg from "../../images/cross.png";
import ArrowImg from "../../images/arrow-quiz.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_PATH } from '../../../Variables';

const quizQuestions = [
  {
    question: "Czy styropian nadaje się do recyklingu?",
    answers: ["Nie, styropianu nie można poddać recyklingowi", "Tak, ale można przetwarzać tylko czysty styropian", "Tak, styropian można poddać recyklingowi mechanicznemu lub chemicznemu, w zależności od stopnia jego zabrudzenia"],
    correctAnswer: "Tak, styropian można poddać recyklingowi mechanicznemu lub chemicznemu, w zależności od stopnia jego zabrudzenia"
  },
  {
    question: "Czy styropian jest bezpieczny dla środowiska?",
    answers: ["Styropian jest bezpieczny dla środowiska i nie powoduje zagrożenia dla powietrza, gleby i wody. Sam proces produkcji styropianu nie wymaga dużego zapotrzebowania na energię, a podczas montażu płyt nie są wydzielane szkodliwe pyły", "Styropian może być szkodliwy dla środowiska. Podczas jego produkcji powstają toksyczne odpady, które w przypadku przedostania się do środowiska,  stwarzają zagrożenie dla ludzi i zwierząt", "Proces produkcji styropianu nie jest uciążliwy dla środowiska, ale jego docinanie i montaż powodują wydzielanie toksycznych pyłów"],
    correctAnswer: "Styropian jest bezpieczny dla środowiska i nie powoduje zagrożenia dla powietrza, gleby i wody. Sam proces produkcji styropianu nie wymaga dużego zapotrzebowania na energię, a podczas montażu płyt nie są wydzielane szkodliwe pyły"
  },
  {
    question: "Styropian (EPS) składa się głównie z dwóch składników: polistyrenu i powierza. Jaki procent objętości styropianu stanowi powietrze?",
    answers: ["50%", "70%", "98%"],
    correctAnswer: "98%"
  },
  {
    question: "Jakie są korzyści dla środowiska wynikające z użycia styropianu EPS w budownictwie?",
    answers: ["Zmniejszenie zużycia energii w budynkach prowadzi do obniżenia emisji gazów cieplarnianych.", "Styropian EPS jest całkowicie odnawialnym źródłem energii", "Styropian EPS pochłania CO2 z atmosfery"],
    correctAnswer: "Zmniejszenie zużycia energii w budynkach prowadzi do obniżenia emisji gazów cieplarnianych."
  },
  {
    question: 'Czym jest inicjatywa "More Zeros Challenge" firmy Yetico i jakie są jej główne cele?',
    answers: ['Inicjatywa "More Zeros Challenge" firmy Yetico polega na zwiększaniu produkcji styropianu EPS, aby osiągnąć większe zyski i zwiększyć udział w rynku', 'Inicjatywa "More Zeros Challenge" Yetico wyraża dążenia firmy w kwestiach środowiskowych. Jej celem jest eliminowanie odpadów, redukcja emisji gazów cieplarnianych czy redukcja zużycia zasobów naturalnych', 'Inicjatywa "More Zeros Challenge" firmy Yetico dąży do zwiększenia efektywności energetycznej budynków poprzez zastosowanie nowych technologii izolacyjnych'],
    correctAnswer: 'Inicjatywa "More Zeros Challenge" Yetico wyraża dążenia firmy w kwestiach środowiskowych. Jej celem jest eliminowanie odpadów, redukcja emisji gazów cieplarnianych czy redukcja zużycia zasobów naturalnych'
  },
];

const validationSchema = Yup.object({
  answer: Yup.string().required("Proszę zaznaczyć odpowiedź!"),
});

const QuizComponent = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [userAnswers, setUserAnswers] = useState([]);
  const [attemptCount, setAttemptCount] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [chances, setChances] = useState(3); // domyślnie 3 szanse
  const [additionalPrize, setAdditionalPrize] = useState(null);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (user && user.id) {
      fetchChances(user.id);
    }
  }, [user]);

  const checkAdditionalPrize = async () => {
    try {
      const response = await axios.get(`${API_PATH}/check-additional-price-quiz-3`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      setAdditionalPrize(response.data);
    } catch (error) {
      console.error('Error checking additional prize:', error);
    }
  };  

  const fetchChances = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_PATH}/quiz3-chances`, { user_id: userId }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data) {
        setChances(response.data.chances);
      }
    } catch (error) {
      console.error('Error fetching chances from API:', error);
    }
  };

  const updateChances = async (userId, newChances) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_PATH}/quiz3-chances`, {
        user_id: userId,
        chances: newChances
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setChances(newChances);
    } catch (error) {
      console.error('Error updating chances in API:', error);
    }
  };

  const sendQuizResultToAPI = async (userId) => {
    const passed = true;
    const passedDate = new Date().toISOString().split('T')[0]; // aktualna data w formacie YYYY-MM-DD

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_PATH}/quiz3data`, {
        user_id: userId,
        passed: passed,
        passed_date: passedDate
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Response from API:', response.data);
    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  };

  const sendQuizFailedToAPI = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      const passedDate = new Date().toISOString().split('T')[0]; // aktualna data w formacie YYYY-MM-DD
      const response = await axios.post(`${API_PATH}/quiz3data-failed`, {
        user_id: userId,
        passed_date: passedDate
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Response from API (failed):', response.data);
    } catch (error) {
      console.error('Error sending data to API (failed):', error);
    }
  };

  const handleSubmit = (values) => {
    const answers = [...userAnswers];
    answers[currentQuestionIndex] = values.answer;
    setUserAnswers(answers);
  
    if (currentQuestionIndex < quizQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const incorrect = quizQuestions
        .map((q, index) => q.correctAnswer !== answers[index] ? index + 1 : null)
        .filter(index => index !== null);
  
      setIncorrectAnswers(incorrect);
      setAttemptCount(attemptCount + 1);
  
      setQuizCompleted(true);
  
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.id) {
        if (incorrect.length === 0) {
          sendQuizResultToAPI(user.id).then(() => checkAdditionalPrize());
        } else {
          const remainingChances = chances - 1;
          updateChances(user.id, remainingChances);
  
          if (remainingChances <= 0) {
            sendQuizFailedToAPI(user.id);
          }
        }
      }
    }
  };  

  const handleRetry = () => {
    setUserAnswers([]);
    setCurrentQuestionIndex(-1);
    setIncorrectAnswers([]);
    setQuizCompleted(false);
  };

  const getSzansaText = (count) => {
    if (count === 1) return "szansę";
    if (count > 1 && count < 5) return "szanse";
    return "szans";
  };

  const renderResults = () => {
    if (incorrectAnswers.length === 0) {
      return (
        <div className='quiz-wrapper'>
          <>
            <p>Gratulujemy!</p>
            <p>Test ukończony pomyślnie.</p>
            <p className='quiz-text-space'>Extra 5 punktów leci na Twoje konto.</p>
          </>
          <button className='btn-quiz-retry' onClick={handleGoBack}>Wracam do listy quizów</button>
        </div>
      );
    } else if (chances <= 0) {
      return (
        <div className='quiz-wrapper'>
          <p>Twoje szanse się skończyły.</p>
          <p>Powodzenia w następnych quizach!</p>
          <button className='btn-quiz-retry' onClick={handleGoBack}>Powrót</button>
        </div>
      );
    } else {
      return (
        <div className='quiz-wrapper'>
          <p>Niestety tym razem Ci się nie udało.</p>
          <p>Masz jeszcze {chances} {getSzansaText(chances)}.</p>
          <p className='quiz-red'>Numery pytań z błędną odpowiedzią: <br /> {incorrectAnswers.join(", ")}</p>
          <button className='btn-quiz-retry' onClick={handleRetry}>Poprawiam odpowiedzi</button>
        </div>
      );
    }
  };

  const closeQuiz = () => {
    navigate('/quizzes');
  }
  const handleGoBack = () => {
    navigate('/quizzes');
  }

  return (
    <>
      <Header />
      <div className='quizzes'>
        <div className='quizzes__inner-single'>
          <PageTitle title="Quiz 1" />
          {currentQuestionIndex === -1 ? (
            <div className='quiz-wrapper'>
              <button className='close-btn' onClick={closeQuiz}><img src={CrossImg} alt="Close" /></button>
              <h2>Quiz 3</h2>
              <span className='quiz-start-text'>Kliknij by rozpocząć</span>
              <button className='btn-quiz btn-quiz-start' onClick={() => setCurrentQuestionIndex(0)}>
                <img src={ArrowImg} alt='Dalej' className='arrow-quiz arrow-quiz-right' />
              </button>
            </div>
          ) : quizCompleted ? (
            renderResults()
          ) : (
            <Formik
              key={currentQuestionIndex}
              initialValues={{ answer: userAnswers[currentQuestionIndex] || '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <button className='close-btn' onClick={closeQuiz}><img src={CrossImg} alt="Close" /></button>
                <h2 className='quiz-question'>{currentQuestionIndex + 1}. {quizQuestions[currentQuestionIndex].question}</h2>
                {quizQuestions[currentQuestionIndex].answers.map((answer, index) => (
                  <div key={index} className='quiz-answer'>
                    <Field type="radio" name="answer" value={answer} id={`answer${index}`} />
                    <label htmlFor={`answer${index}`}>{answer}</label>
                  </div>
                ))}
                <ErrorMessage name="answer" component="div" className="error" />
                <div>
                  {currentQuestionIndex >= 0 && (
                    <button type="button" onClick={() => currentQuestionIndex === 0 ? setCurrentQuestionIndex(-1) : setCurrentQuestionIndex(currentQuestionIndex - 1)} className='btn-quiz btn-quiz-back'>
                      <img src={ArrowImg} alt='Cofnij' className='arrow-quiz arrow-quiz-left' />
                    </button>
                  )}
                  <button type="submit" className='btn-quiz btn-quiz-next'>
                    <img src={ArrowImg} alt={currentQuestionIndex === quizQuestions.length - 1 ? 'Zakończ' : 'Dalej'} className='arrow-quiz arrow-quiz-right' />
                  </button>
                </div>
              </Form>
            </Formik>
          )}
          {currentQuestionIndex >= 0 && !quizCompleted && (
            <div className='quiz-steps'><span className='span-blue'>{currentQuestionIndex + 1} /</span> {quizQuestions.length}</div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default QuizComponent;