import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoginData from './LoginData';
import Password from './Password';
import CompanyData from './CompanyData';
import HistoryOfOrders from './HistoryOfOrders';
import PageTitle from '../../../components/main/PageTitle';
import { API_PATH } from '../../../../Variables';

function Main() {
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    const userName = user.name;
    const userId = user.id;

    const [points, setPoints] = useState(0);
    const [userCode, setUserCode] = useState('');
    const token = localStorage.getItem('token');
    const [quizStatus, setQuizStatus] = useState('');

    useEffect(() => {
        const fetchUserCode = async () => {
            try {
                const response = await axios.get(`${API_PATH}/quiz-status/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setQuizStatus(response.data.status);
            } catch (error) {
                console.error('Error fetching user code:', error);
            }
        };

        fetchUserCode();
    }, [userId, token]);

    useEffect(() => {
        const fetchUserCode = async () => {
            try {
                const response = await axios.get(`${API_PATH}/user/${userId}/code`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUserCode(response.data.code);
            } catch (error) {
                console.error('Error fetching user code:', error);
            }
        };

        fetchUserCode();
    }, [userId, token]);

    useEffect(() => {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        const userId = user.id;
      
        const fetchPoints = async () => {
          try {
            const response = await axios.get(`${API_PATH}/user/${userId}/points`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPoints(response.data.points);
          } catch (error) {
            console.error('Error fetching points:', error);
          }
        };
      
        fetchPoints();
      }, [userId, token]);

    return (
        <section className="account">
            <PageTitle title="Moje konto" />
            <div className="account__inner">
                <h1 className="account__title">Moje konto</h1>
                <div className="account__user_info">
                    <p className="account__user_name">Cześć {userName}</p>
                    <p className="account__user_points">Posiadasz: <strong>{points} pkt</strong></p>
                    <p className="account__user_quizzes">Wypełnione quizy: <strong>{quizStatus}</strong></p>
                </div>
                <p className="account__code"><strong>Twój unikalny kod:</strong> {userCode}</p>
                <div className="account__content">
                    <LoginData />
                    <Password />
                    <CompanyData />
                </div>
                <HistoryOfOrders />
            </div>
        </section>
    );
}

export default Main;