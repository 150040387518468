import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../pages/auth/AuthContext';
import axios from 'axios';
import { API_PATH } from '../../../Variables';

const Navigation = () => {

  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
          await axios.post(`${API_PATH}/logout`, {}, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          logout();
          navigate('/admin');
      } catch (error) {
          console.error('Logout failed:', error);
      }
  } else {
    console.error('Logout failed: No token found');
  }
  }

  return (
    <AppBar position="static" style={{background: '#222'}}>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Panel administracyjny
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/admin/users" style={{marginRight: '10px'}}>
          Użytkownicy
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/admin/orders" style={{marginRight: '10px'}}>
          Zamówienia
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/admin/import-export" style={{marginRight: '10px'}}>
          Importuj / Eksportuj Użytkowników
        </Button>
        <Button variant="contained" color="primary" component={Link} onClick={handleLogout}>
          Wyloguj
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;