import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Container, Typography, Button, Grid, Paper } from '@mui/material';
import Nav from '../../components/Nav';
import { API_PATH } from '../../../../Variables';

const UserDetails = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [userCode, setUserCode] = useState('');

  useEffect(() => {
    const fetchUserCode = async () => {
        try {
            const response = await axios.get(`${API_PATH}/user/${userId}/code`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setUserCode(response.data.code);
        } catch (error) {
            console.error('Error fetching user code:', error);
        }
    };
      fetchUserCode();
  }, [userId, token]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${API_PATH}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
        }
        });
        const userData = response.data;
        setUser(userData);

        // Fetch the file URL if the user has uploaded a file
        if (userData.file_uploaded) {
          const fileResponse = await axios.get(`${API_PATH}/user/${userId}/file`, {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          const fileBlob = new Blob([fileResponse.data], { type: 'video/mp4' });
          const fileUrl = URL.createObjectURL(fileBlob);
          setFileUrl(fileUrl);
        }
      } catch (error) {
        console.error('Error fetching user or file:', error);
      }
    };

    fetchUser();
  }, [userId, token]);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `user_${userId}_video.mp4`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!user) {
    return (
      <>
        <Nav />
        <Container style={{ textAlign: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </Container>
      </>
    );
  }

  return (
    <>
      <Nav />
      <Container style={{ marginTop: '20px', maxWidth: '80%' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Dane użytkownika
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Imię:</Typography>
              <Typography>{user.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Nazwisko:</Typography>
              <Typography>{user.lastname}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Adres e-mail:</Typography>
              <Typography>{user.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Numer telefonu:</Typography>
              <Typography>{user.phone_number}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Nazwa firmy:</Typography>
              <Typography>{user.company_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Kod pocztowy:</Typography>
              <Typography>{user.zip_code}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Miasto:</Typography>
              <Typography>{user.city}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Ulica:</Typography>
              <Typography>{user.street_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Numer ulicy:</Typography>
              <Typography>{user.street_number}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Kontakt telefoniczny:</Typography>
              <Typography>{user.phone_contact ? 'Tak' : 'Nie'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Kontakt e-mail:</Typography>
              <Typography>{user.email_contact ? 'Tak' : 'Nie'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Typ użytkownika:</Typography>
              <Typography>{user.user_type === 'owner' ? 'Właściciel' : 'Pracownik'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Marketing:</Typography>
              <Typography>{user.marketing ? 'Tak' : 'Nie'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Czy zgłoszenie wysłane:</Typography>
              <Typography>{user.file_uploaded ? 'Tak' : 'Nie'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Ostatnie logowanie:</Typography>
              <Typography>{user.last_login_date ? user.last_login_date : 'Użytkownik jeszcze się nie zalogował.'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Data rejestracji:</Typography>
              <Typography>{user.registration_date ? user.registration_date : 'Brak daty'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Unikalny kod:</Typography>
              <Typography>{userCode}</Typography>
            </Grid>
          </Grid>
          {fileUrl && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ marginTop: '20px' }}>Załączony plik wideo:</Typography>
                <video width="100%" controls>
                  <source src={fileUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownload}
                  style={{ marginTop: '10px' }}
                >
                  Pobierz wideo
                </Button>
              </Grid>
            </Grid>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/admin/users')}
            style={{ marginTop: '20px' }}
          >
            Wróć do listy użytkowników
          </Button>
        </Paper>
      </Container>
    </>
  );
};

export default UserDetails;