import React, { useState, useEffect } from 'react';
import PageTitle from '../../../components/main/PageTitle';
import axios from 'axios';
import getImagePath from '../../../../utils/getImagePath';
import ScrollToTopNavLink from '../../../components/main/ScrollToTopNavLink';
import { API_PATH } from '../../../../Variables';
import EkspresImg from '../../../images/70_6_ ekspres2.png';
import Karta150Img from '../../../images/30_12_karta150.png';
import KuchenkaImg from '../../../images/70_7_ kuchenka2.png';
import Karta450Img from '../../../images/70_8_ karta3502.png';

function Main() {
    const [awards30, setAwards30] = useState([]);
    const [awards70, setAwards70] = useState([]);
    const [awards150, setAwards150] = useState([]);
    const [awards250, setAwards250] = useState([]);
    const [awards400, setAwards400] = useState([]);

    const fetchData = async (url, setState) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setState(response.data);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    useEffect(() => {
        fetchData(`${API_PATH}/awards/30pkt`, setAwards30);
        fetchData(`${API_PATH}/awards/70pkt`, setAwards70);
        fetchData(`${API_PATH}/awards/150pkt`, setAwards150);
        fetchData(`${API_PATH}/awards/250pkt`, setAwards250);
        fetchData(`${API_PATH}/awards/400pkt`, setAwards400);
    }, []);

    const getGlobalImagePath = (imgName, defaultImage) => {
        if(imgName === 'Ekspres DELONGHI Dolce Gusto Mini Me') {
            return EkspresImg;
        } if (imgName === 'Karta przedpłacona 150 zł') {
            return Karta150Img;
        } if (imgName === 'Kuchenka mikrofalowa AMICA AMGF20M1W') {
            return KuchenkaImg;
        } if(imgName === 'Karta przedpłacona Pluxee 350 zł') {
            return Karta450Img;
        } else {
            return defaultImage;
        }
    }

    const renderAwards = (awards) => {
        return awards.map((award) => {
            const imagePath = getImagePath(award.img_name);

            return (
                <ScrollToTopNavLink to={`/awards/${award.id}`} className='awards-page__container_link' key={award.id}>
                    <img src={getGlobalImagePath(award.name, imagePath)} alt={award.name} />
                    <div className='awards-page__points'>{award.points} pkt</div>
                    <button className='awards-page__container_button'>zobacz szczegóły</button>
                </ScrollToTopNavLink>
            );
        });
    };

    return (
        <section className='awards-page'>
            <PageTitle title="Nagrody" />
            <div className='awards-page__information'>zamawianie nagród od 21.10.2024</div>
            <div className='awards-page__inner'>
                <h1 className='awards-page__title'>Nagrody</h1>
                <p className='awards-page__subtitle'>Przybij piątkę!<br />  5 progów zakupowych.</p>
                <div className='awards-page__main_wrapper'>
                    <div className='awards-page__container_wrapper' id='awards30'>
                        <div className='awards-page__container_text'>
                            <p className='awards-page__container_points'>od 30 pkt</p>
                            <p className='awards-page__container_title'>PRÓG STARTOWY</p>
                            <div className='awards-page__container_awards_wrapper'>
                                {renderAwards(awards30)}
                            </div>
                        </div>
                    </div>
                    <div className='awards-page__container_wrapper' id='awards70'>
                        <div className='awards-page__container_text'>
                            <p className='awards-page__container_points'>od 70 pkt</p>
                            <p className='awards-page__container_title'>PRÓG ZAAWANSOWANY</p>
                            <div className='awards-page__container_awards_wrapper'>
                                {renderAwards(awards70)}
                            </div>
                        </div>
                    </div>
                    <div className='awards-page__container_wrapper' id='awards150'>
                        <div className='awards-page__container_text'>
                            <p className='awards-page__container_points'>od 150 pkt</p>
                            <p className='awards-page__container_title'>PRÓG PREMIUM</p>
                            <div className='awards-page__container_awards_wrapper'>
                                {renderAwards(awards150)}
                            </div>
                        </div>
                    </div>
                    <div className='awards-page__container_wrapper' id='awards250'>
                        <div className='awards-page__container_text'>
                            <p className='awards-page__container_points'>od 250 pkt</p>
                            <p className='awards-page__container_title'>PRÓG EKSPANSYWNY</p>
                            <div className='awards-page__container_awards_wrapper'>
                                {renderAwards(awards250)}
                            </div>
                        </div>
                    </div>
                    <div className='awards-page__container_wrapper' id='awards400'>
                        <div className='awards-page__container_text'>
                            <p className='awards-page__container_points'>od 400 pkt</p>
                            <p className='awards-page__container_title'>PRÓG MISTRZOWSKI</p>
                            <div className='awards-page__container_awards_wrapper'>
                                {renderAwards(awards400)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Main;