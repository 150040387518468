import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import Nav from '../../components/Nav';
import { API_PATH } from '../../../../Variables';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false); // New state for download loading
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${API_PATH}/orders`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [token]);

  const getSettlementText = (settlement) => {
    return settlement === 'natural person' ? 'Osoba fizyczna' : 'Firma';
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    try {
      const response = await axios.get(`${API_PATH}/export-orders`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'orders.csv';
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Błąd podczas pobierania zamówień:', error);
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <>
      <Nav />
      <TableContainer component={Paper} sx={{ margin: '20px auto', padding: '20px', maxWidth: '80%' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownload}
          disabled={downloadLoading}
          sx={{ marginBottom: '20px' }}
        >
          {downloadLoading ? 'Pobieranie...' : 'Pobierz wykaz zamówień'}
        </Button>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        ) : (
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell>ID zamówienia</TableCell>
                <TableCell>Data zamówienia</TableCell>
                <TableCell>Nagroda</TableCell>
                <TableCell>Ilość</TableCell>
                <TableCell>Rozliczenie nagrody</TableCell>
                <TableCell>Imię</TableCell>
                <TableCell>Nazwisko</TableCell>
                <TableCell>Nazwa firmy</TableCell>
                <TableCell>Miejscowość</TableCell>
                <TableCell>Kod pocztowy</TableCell>
                <TableCell>Ulica</TableCell>
                <TableCell>Nr domu/lokalu</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <TableRow key={order.id} sx={{ backgroundColor: index % 2 ? '#f9f9f9' : '#fff' }}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.created_at}</TableCell>
                  <TableCell>{order.awards_name}</TableCell>
                  <TableCell>{order.quantity}</TableCell>
                  <TableCell>{getSettlementText(order.settlement_of_the_award)}</TableCell>
                  <TableCell>{order.name}</TableCell>
                  <TableCell>{order.last_name}</TableCell>
                  <TableCell>{order.company_name}</TableCell>
                  <TableCell>{order.city}</TableCell>
                  <TableCell>{order.zip_code}</TableCell>
                  <TableCell>{order.street}</TableCell>
                  <TableCell>{order.street_number}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default Orders;