import PageTitle from '../../../components/main/PageTitle';
import MobileBackground from '../../../images/fuerta-mobile.png';
import Header from '../../../components/header/Header';
import ScrollMouse from '../../../images/scroll_animation_icon.svg';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function WelcomeSection() {

    const ScrollToSection = () => {
        const { hash } = useLocation();
      
        useEffect(() => {
          if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }, [hash]);
      
        return null;
    };

    const navigate = useNavigate();

    const handleScrollClick = () => {
        navigate('/competition#competition-rules');
    }


    return (
      <section className="homelogged homelogged--competition">
        <PageTitle title="Konkurs" />
        <ScrollToSection />
        <Header />
        <div className='homelogged__leftpanel homelogged__leftpanel--competition'>
            <div className='homelogged__panel homelogged__panel--competition'>
                <img className='homelogged__background--mobile' src={MobileBackground} alt='Yetico' />
                <div className='homelogged__wrapper_bottom homelogged__wrapper_bottom--competition'>
                    <div className='competition__wrapper'>
                        <h1>Hi<br />Fuerteventura!</h1>
                        <p>KONKURS KREATYWNY</p>
                    </div>
                    <p>Konkurs dobiegł końca. Dziękujemy za udział!</p>
                    <p><strong>Poniżej prezentujemy zwycięzców oraz ich konkursowe zgłoszenia</strong></p>
                   
                </div>
            </div>
            <button className='homelogged__scroll' onClick={handleScrollClick}>
                <img className='homelogged__scroll--icon' src={ScrollMouse} alt='Scroll' />
                <p className='homelogged__scroll--text'>dowiedz się więcej</p>
            </button>
        </div>
      </section>
    );
  }
  
  export default WelcomeSection;