import AwardsImage from '../../../images/nagrody.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function AwardsSection() {

    const navigate = useNavigate();

    const ScrollToSection = () => {
        const { hash } = useLocation();
      
        useEffect(() => {
          if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }, [hash]);
      
        return null;
    };

    const handleScroll30 = () => {
        navigate('/awards#awards30');
    }

    const handleScroll70 = () => {
        navigate('/awards#awards70');
    }

    const handleScroll150 = () => {
        navigate('/awards#awards150');
    }

    const handleScroll250 = () => {
        navigate('/awards#awards250');
    }
    const handleScroll400 = () => {
        navigate('/awards#awards400');
    }

    return (
      <section className="awards" id="awards">
        <ScrollToSection />
        <div className="awards__inner">
            <h2 className="awards__title">NAGRODY</h2>
            <p className="awards__subtitle">Przybij piątkę! 5 progów zakupowych.</p>
            <div className="awards__wrapper">
                <div className="awards__wrapper2">
                    <div className="awards__container">
                        <div className="awards__points">od 30 pkt</div>
                        <div className="awards__container_title">PRÓG STARTOWY</div>
                        <div className="awards__container_subtitle">Zakupy powyżej 30 000 zł netto</div>
                        <button className="awards__container_button" onClick={handleScroll30}>Sprawdzam nagrody</button>
                    </div>
                    <div className="awards__container">
                        <div className="awards__points">od 70 pkt</div>
                        <div className="awards__container_title">PRÓG ZAAWANSOWANY</div>
                        <div className="awards__container_subtitle">Zakupy powyżej 70 000 zł netto</div>
                        <button className="awards__container_button" onClick={handleScroll70}>Sprawdzam nagrody</button>
                    </div>
                    <div className="awards__container">
                        <div className="awards__points">od 150 pkt</div>
                        <div className="awards__container_title">PRÓG PREMIUM</div>
                        <div className="awards__container_subtitle">Zakupy powyżej 150 000 zł netto</div>
                        <button className="awards__container_button" onClick={handleScroll150}>Sprawdzam nagrody</button>
                    </div>
                    <div className="awards__container">
                        <div className="awards__points">od 250 pkt</div>
                        <div className="awards__container_title">PRÓG EKSPANSYWNY</div>
                        <div className="awards__container_subtitle">Zakupy powyżej 250 000 zł netto</div>
                        <button className="awards__container_button" onClick={handleScroll250}>Sprawdzam nagrody</button>
                    </div>
                    <div className="awards__container">
                        <div className="awards__points">od 400 pkt</div>
                        <div className="awards__container_title">PRÓG MISTRZOWSKI</div>
                        <div className="awards__container_subtitle">Zakupy powyżej 400 000 zł netto</div>
                        <button className="awards__container_button" onClick={handleScroll400}>Sprawdzam nagrody</button>
                    </div>
                </div>
                <div className="awards__image">
                    <img src={AwardsImage} alt="Nagrody" />
                </div>
            </div>
        </div>
      </section>
    );
  }
  
export default AwardsSection;