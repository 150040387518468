import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { API_PATH } from '../../../Variables';
import YeticoLogo from '../../images/logo_yetico_menu.svg';
import MobileBackground from '../../images/baner-logowanie.png';
import PageTitle from '../../components/main/PageTitle';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SetNewPassword() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();
    const token = query.get('token');
    const email = query.get('email');

    const [showPassword, setShowPassword] = useState({
        newPassword: false,
        repeatNewPassword: false
      });

      const togglePasswordVisibility = (field) => {
        setShowPassword((prevState) => ({
          ...prevState,
          [field]: !prevState[field]
        }));
      };

    const initialValues = {
        newPassword: '',
        repeatNewPassword: ''
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .required('Wymagane')
            .min(8, 'Hasło musi mieć co najmniej 8 znaków')
            .matches(/[a-z]/, 'Musi zawierać co najmniej jedną małą literę')
            .matches(/[A-Z]/, 'Musi zawierać co najmniej jedną dużą literę')
            .matches(/\d/, 'Musi zawierać co najmniej jedną cyfrę')
            .matches(/[@$!%*?&_+]/, 'Musi zawierać co najmniej jeden znak specjalny'),
        repeatNewPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Hasła muszą być takie same')
            .required('Wymagane pole')
    });

    const handleSubmit = async (values, { setErrors }) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_PATH}/reset-password`, {
                email,
                token,
                password: values.newPassword,
                password_confirmation: values.repeatNewPassword,
            });
            if (response.data.message) {
                navigate('/');
                setLoading(false);
            } else {
                setErrors({ newPassword: response.data.message });
                setLoading(false);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrors({ newPassword: error.response.data.message });
                setLoading(false);
            } else {
                setErrors({ newPassword: 'Wystąpił błąd. Spróbuj ponownie później.' });
                setLoading(false);
            }
        }
    };

    return (
        <section className="home">
            <PageTitle title="Ustaw nowe hasło" />
            <div className='home__leftpanel'>
                <div className='home__panel'>
                    <img className='home__background--mobile' src={MobileBackground} alt='Yetico' />
                    <div className="home__header">
                        <NavLink to='/' className='home__header--logo'>
                            <img src={YeticoLogo} alt="Yetico" />
                        </NavLink>
                    </div>
                    <div className='home__wrapper_bottom'>
                        <div className='home__text'>
                            <h1 className='home__title'>Hi Yetico!</h1>
                            <p className='home__subtitle--bold'>Budujemy ciepłe relacje</p>
                            <p className='remindpassword__subtitle'>Akcja sprzedażowo-lojalnościowa</p>
                        </div>
                        <div className='emailsended__wrapper'>
                            <div className='home__welcome'>
                                <h2 className='emailsended__welcome_title'>Ustaw nowe hasło<br />do Hi Yetico!</h2>
                            </div>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, handleChange }) => (
                                <Form className='setnewpassword__form'>
                                    <div className='inputs-wrapper'>
                                        <div className='home__input_wrapper'>
                                            <Field
                                            className='home__input home__input--password'
                                            type={showPassword.newPassword ? 'text' : 'password'}
                                            name='newPassword'
                                            placeholder='Nowe hasło'
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            autoComplete='current password'
                                            />
                                            <button
                                            type='button'
                                            onClick={() => togglePasswordVisibility('newPassword')}
                                            className='home__toggle_password'
                                            aria-label='Toggle password visibility'
                                            >
                                            {showPassword.newPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                            <PasswordRequirements password={values.newPassword} />
                                            <ErrorMessage name='newPassword' component='div' className='home__error' />
                                        </div>
                                        <div className='home__input_wrapper'>
                                            <Field
                                            className='home__input home__input--password'
                                            type={showPassword.repeatNewPassword ? 'text' : 'password'}
                                            name='repeatNewPassword'
                                            placeholder='Powtórz nowe hasło'
                                            autoComplete='current password'
                                            />
                                            <button
                                            type='button'
                                            onClick={() => togglePasswordVisibility('repeatNewPassword')}
                                            className='home__toggle_password'
                                            aria-label='Toggle password visibility'
                                            >
                                            {showPassword.repeatNewPassword ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                            <ErrorMessage name='repeatNewPassword' component='div' className='home__error' />
                                        </div>
                                    </div>
                                    <div className='home__form_button'>
                                        <button className='home__button--login' type='submit'>{loading ? <div className="loader"></div> : 'Zapisz'}</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SetNewPassword;

const PasswordRequirements = ({ password }) => {
    const requirements = [
        { regex: /.{8,}/, label: 'Co najmniej 8 znaków' },
        { regex: /[a-z]/, label: 'Co najmniej jedna mała litera' },
        { regex: /[A-Z]/, label: 'Co najmniej jedna duża litera' },
        { regex: /\d/, label: 'Co najmniej jedna cyfra' },
        { regex: /[@$!%*?&_+]/, label: 'Co najmniej jeden znak specjalny' },
    ];

    return (
        <div className='password-requirements'>
            {requirements.map((req, index) => (
                <div key={index} style={{ color: req.regex.test(password) ? 'green' : 'red', fontWeight: 'bold' }}>
                    {req.label}
                </div>
            ))}
        </div>
    );
};