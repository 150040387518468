import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return localStorage.getItem('isAuthenticated') === 'true';
    });
    const [userEmail, setUserEmail] = useState(() => {
        return localStorage.getItem('userEmail');
    });

    useEffect(() => {
        setIsAuthenticated(localStorage.getItem('isAuthenticated') === 'true');
        setUserEmail(localStorage.getItem('userEmail'));
    }, []);

    const login = (userData, userToken) => {
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('userEmail', userData.email);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('token', userToken);
        setIsAuthenticated(true);
        setUserEmail(userData.email);
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('userEmail');
        localStorage.setItem('isAuthenticated', 'false');
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserEmail(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userEmail, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);