import YeticoLogo from '../../images/logo_yetico_menu.svg';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import UserLogo from '../../images/user.svg';
import { useState, useEffect } from 'react';
import { useAuth } from '../../pages/auth/AuthContext';
import axios from 'axios';
import { API_PATH } from '../../../Variables';

import LogoutIco from '../../images/wyloguj_ico.svg';
import ContactIcon from '../../images/kontakt_ico.svg';
import RulesIco from '../../images/zasady_ico.svg';
import ProductsIco from '../../images/produkty_ico.svg';
import QuizzesIco from '../../images/quizy_ico.svg';
import CompetitionIco from '../../images/konkurs_ico.svg';
import AwardsIco from '../../images/nagrody_ico.svg';
import AccountIco from '../../images/moje_konto_ico.svg';

function Header() {
  const [scrolling, setScrolling] = useState(false);
  const [points, setPoints] = useState(0);
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);

  const ScrollToSection = () => {
    const { hash } = useLocation();
  
    useEffect(() => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [hash]);
  
    return null;
  };

  useEffect(() => {
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    const userId = user.id;
  
    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_PATH}/user/${userId}/points`, {
          headers: {
            Authorization: `Bearer ${token}`
        }
        });
        setPoints(response.data.points);
      } catch (error) {
        console.error('Error fetching points:', error);
      }
    };
  
    fetchPoints();
  }, []);  

  const mobileHeaderAnimate = {
    backgroundColor: scrolling ? '#ffffff' : 'transparent',
    transition: 'background-color 0.3s ease',
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        setLoading(true);
          await axios.post(`${API_PATH}/logout`, {}, {
              headers: {
                  Authorization: `Bearer ${token}`
              }
          });
          logout();
          navigate('/');
          setLoading(false);
      } catch (error) {
          console.error('Logout failed:', error);
          setLoading(false);
      }
  } else {
    console.error('Logout failed: No token found');
    setLoading(false);
  }
  }

  const handleOpenAccountPage = () => {
    setIsMenuOpen(false); // Close mobile menu
    navigate('/account'); // Navigate to the link destination
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  }

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  }

  const handleNavLinkClick = (to) => {
    setIsMenuOpen(false); // Close mobile menu
    navigate(to); // Navigate to the link destination
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  }

  const getUserName = () => {
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    const userName = user.name;
    return userName;
  }

  return (
    <>
      <ScrollToSection />
      <header className="header">
      <div className='header__inner'>
        <div className="header__logo" onClick={() => handleNavLinkClick('/home')}>
            <NavLink to="/home">
                <img src={YeticoLogo} alt="Yetico" />
            </NavLink>
        </div>
        <nav className="header__nav_desktop">
            <ul>
                <li>
                    <NavLink to="/home#actions-rules" className='header__nav_center' onClick={() => handleNavLinkClick('/home#actions-rules')}>Zasady akcji</NavLink>
                </li>
                <li>
                    <NavLink to="/sale-products" className='header__nav_center' onClick={() => handleNavLinkClick('/sale-products')}>Produkty<br />promocyjne</NavLink>
                </li>
                <li>
                    <NavLink to="/awards" onClick={() => handleNavLinkClick('/awards')}>Nagrody</NavLink>
                </li>
                <li>
                    <NavLink to="/quizzes" onClick={() => handleNavLinkClick('/quizzes')}>Quizy</NavLink>
                </li>
                <li>
                    <NavLink to="/competition" onClick={() => handleNavLinkClick('/competition')}>&nbsp;Konkurs dodatkowy<br/><section><strong>(Poznaj zwyciezców)</strong></section></NavLink>
                </li>
                <li>
                    <NavLink to="/home#contact" onClick={() => handleNavLinkClick('/home#contact')}>Kontakt</NavLink>
                </li>
            </ul>
        </nav>
        <div className='header__user'>
          <button className='header__user_wrapper' onClick={handleOpenAccountPage}>
            <img src={UserLogo}  alt="User" />
            <p className='header__user_name'>{getUserName()}, {points} pkt</p>
          </button>
          <button className='header__user_logout' onClick={() => handleLogout()}>Wyloguj {loading ? <div className="loader"></div> : null}</button>
        </div>
      </div>
      </header>
      <header className='header-mobile' style={mobileHeaderAnimate}>
        <div className='header-mobile__inner'>
          <div className="header__logo" onClick={() => handleNavLinkClick('/home')}>
              <NavLink to="/home">
                  <img className='header__logo--mobile' src={YeticoLogo} alt="Yetico" />
              </NavLink>
          </div>
          <button className='header-mobile__hamburger' onClick={handleOpenMenu}>
              <div className='hamburger__line'></div>
              <div className='hamburger__line'></div>
              <div className='hamburger__line'></div>
          </button>
        </div>
      </header>
      <div className={isMenuOpen ? 'mobile-menu active-mobile-menu' : 'mobile-menu'}>
        <div className='mobile-menu__top'>
          <div className='mobile-menu__logo' onClick={() => handleNavLinkClick('/home')}>
            <img className='header__logo--mobile inverted-logo' src={YeticoLogo} alt='Yetico' />
          </div>
          <button className='mobile-menu__close' onClick={handleCloseMenu}>
            <div className='close__line'></div>
            <div className='close__line'></div>
          </button>
        </div>
        <p className='mobile-menu__user'>Cześć {getUserName()}</p>
        <p className='mobile-menu__points'>Posiadasz: {points} pkt</p>
        <nav className='mobile-menu__nav'>
          <ul>
              <li>
                  <button className='mobile-menu__nav--button' onClick={() => handleLogout()}> <img src={LogoutIco} alt="" /> Wyloguj {loading ? <div className="loader loader--light"></div> : null}</button>
              </li>
              <li>
                  <NavLink to="/home#contact" onClick={() => handleNavLinkClick('/home#contact')}><img src={ContactIcon} alt="" /> Kontakt</NavLink>
              </li>
              <li>
                  <NavLink to="/home#actions-rules" onClick={() => handleNavLinkClick('/home#actions-rules')}><img src={RulesIco} alt="" /> Zasady<br />akcji</NavLink>
              </li>
              <li>
                  <NavLink to="/sale-products" onClick={() => handleNavLinkClick('/sale-products')}><img src={ProductsIco} alt="" />Produkty<br />promocyjne</NavLink>
              </li>
              <li>
                  <NavLink to="/quizzes" onClick={() => handleNavLinkClick('/quizzes')}><img src={QuizzesIco} alt="" /> Quizy</NavLink>
              </li>
              <li>
                  <NavLink to="/competition" onClick={() => handleNavLinkClick('/competition')}><img src={CompetitionIco} alt="" />Konkurs dodatkowy<br />(Poznaj zwyciezców)</NavLink>
              </li>
              <li>
                  <NavLink to="/awards" onClick={() => handleNavLinkClick('/awards')}><img src={AwardsIco} alt="" /> Nagrody</NavLink>
              </li>
              <li>
                  <NavLink to="/account" onClick={() => handleNavLinkClick('/account')}><img src={AccountIco} alt="" /> Moje konto</NavLink>
              </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Header;