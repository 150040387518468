import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Container, Typography, Button, Grid, Paper, TextField, Snackbar, Alert } from '@mui/material';
import Nav from '../../components/Nav';
import { API_PATH } from '../../../../Variables';

const UserEdit = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({
    name: '',
    lastname: '',
    email: '',
    phone_number: '',
    company_name: '',
    zip_code: '',
    city: '',
    street_name: '',
    street_number: '',
  });
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${API_PATH}/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
        }
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${API_PATH}/users/${userId}`, user, {
        headers: {
          Authorization: `Bearer ${token}`
      }
      });
      setOpenSnackbar(true); // Show the Snackbar
      setTimeout(() => {
        navigate('/admin/users'); // Navigate back after a delay
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  if (loading) {
    return (
        <>
            <Nav />
            <Container style={{ textAlign: 'center', marginTop: '20px' }}>
                <CircularProgress />
            </Container>
        </>
    );
  }

  return (
    <>
      <Nav />
      <Container style={{ marginTop: '20px', maxWidth: '80%' }}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Edytuj użytkownika
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Imię"
                  name="name"
                  value={user.name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nazwisko"
                  name="lastname"
                  value={user.lastname}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Adres e-mail"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Numer telefonu"
                  name="phone_number"
                  value={user.phone_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Nazwa firmy"
                  name="company_name"
                  value={user.company_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Kod pocztowy"
                  name="zip_code"
                  value={user.zip_code}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Miasto"
                  name="city"
                  value={user.city}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Ulica"
                  name="street_name"
                  value={user.street_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Numer ulicy"
                  name="street_number"
                  value={user.street_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: '20px', marginRight: '10px' }}
            >
              Zapisz
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/admin/users')}
              style={{ marginTop: '20px' }}
            >
              Wróć do listy użytkowników
            </Button>
          </form>
        </Paper>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Dane użytkownika zostały zaktualizowane!
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserEdit;