import BackgroundImage from '../../../images/sygnet_yetico.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../../../../Variables';

function Contact() {
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const initialValues = {
        name: '',
        lastname: '',
        phonenumber: '',
        email: '',
        title: '',
        message: '',
    };

    const validationSchema = Yup.object({
        name: Yup.string().required('Wymagane pole'),
        lastname: Yup.string().required('Wymagane pole'),
        phonenumber: Yup.string()
        .matches(/^\d{9}$/, 'Numer telefonu musi składać się z dokładnie 9 cyfr')
        .required('Wymagane pole'),
        email: Yup.string().email('Nie poprawny adres e-mail').required('Wymagane pole'),
        title: Yup.string().required('Wymagane pole'),
        message: Yup.string()
        .min(10, 'Wiadomość jest za krótka - powinna mieć co najmniej 10 znaków.')
        .required('Wymagane pole'),
    });

    const handleSubmit = async (values, { resetForm }) => {
        setSubmitting(true);
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${API_PATH}/contact`, values, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSubmitted(true);
            resetForm();
        } catch (error) {
            console.error('Error sending email:', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
      <section className="contact" id='contact'>
        <div className="contact__inner">
        <h2 className="contact__title">Napisz do nas</h2>
            <div className="contact__wrapper">
                <div className="conatct__wrapper_form">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                > 
                {({ values, setFieldValue, handleChange }) => (
                    <Form>
                        <div className='contact__input_wrapper'>
                            <Field className='contact__input' type='text' name='name' placeholder='Imię' />
                            <ErrorMessage name='name' component='div' className='contact__error' />
                        </div>
                        <div className='contact__input_wrapper'>
                            <Field className='contact__input' type='text' name='lastname' placeholder='Nazwisko' />
                            <ErrorMessage name='lastname' component='div' className='contact__error' />
                        </div>
                        <div>
                            <Field
                                className='contact__input'
                                type='text'
                                name='phonenumber'
                                placeholder='Numer telefonu'
                                value={values.phonenumber}
                                onChange={(e) => {
                                const value = e.target.value;
                                const onlyNums = value.replace(/[^\d]/g, '');
                                const trimmedNums = onlyNums.slice(0, 9);
                                setFieldValue('phonenumber', trimmedNums);
                                }}
                            />
                            <ErrorMessage name='phonenumber' component='div' className='contact__error' />
                        </div>
                        <div className='contact__input_wrapper'>
                            <Field className='contact__input' type='text' name='email' placeholder='Adre e-mail' />
                            <ErrorMessage name='email' component='div' className='contact__error' />
                        </div>
                        <div className='contact__input_wrapper'>
                            <Field className='contact__input' type='text' name='title' placeholder='Tytuł zapytania' />
                            <ErrorMessage name='title' component='div' className='contact__error' />
                        </div>
                        <div className='contact__input_wrapper'>
                            <Field
                            className='contact__input contact__input--textarea'
                            as='textarea'
                            name='message'
                            placeholder='Treść wiadomości'
                            />
                            <ErrorMessage name='message' component='div' className='contact__error' />
                        </div>
                        <p className='contact__form_text'>Administratorem danych osobowych osób korzystających z podanych na stronie internetowej danych kontaktowych jest Agencja Look Ad z siedzibą w Poznaniu (60-319), przy ul. Ptasia 10. Więcej informacji na temat przetwarzania danych osobowych użytkowników strony internetowej oraz przysługujących im praw związanych z tym przetwarzaniem, znajdą Państwo w naszej Polityce prywatności</p>
                        <div className='contact__submit_wrapper'>
                            <button type='submit' className='contact__button' disabled={submitting}>
                                {submitting ? <div className="loader"></div> : 'Wyślij wiadomość'}
                            </button>
                        </div>
                        {submitted && <p className='contact__success'>Wiadomość została wysłana!</p>}
                    </Form>
                )}
                </Formik>
                </div>
                <div className='contact-space-div'></div>
            </div>
            <img src={BackgroundImage} alt="Logo Yetico" className="section-rules__image-bg" />
        </div>
      </section>
    );
}

export default Contact;