import React, { useState } from 'react';
import { TextField, Button, Box, Container, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_PATH } from '../../../Variables';
import { useAuth } from '../../pages/auth/AuthContext';

const LoginComponent = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the request starts
    try {
      const response = await axios.post(`${API_PATH}/admin-login`, {
        email: credentials.username,
        password: credentials.password,
      });

      if (response.data.status === 'success') {
        const userData = response.data.user;
        const userToken = response.data.token;
        login(userData, userToken);
        navigate('/admin/users');
      } else {
        setError('Nieprawidłowy e-mail lub hasło.');
      }
    } catch (err) {
      setError('Nieprawidłowy e-mail lub hasło.');
      console.error('Login error:', err);
    } finally {
      setLoading(false); // Set loading to false when the request finishes
    }
  };

  return (
    <Container maxWidth="sm">
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        minHeight="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Logowanie Administratora
        </Typography>
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Adres e-mail"
            name="username"
            autoComplete="username"
            autoFocus
            value={credentials.username}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
            value={credentials.password}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading} // Disable button while loading
            sx={{ position: 'relative' }} // Position relative for loader
          >
            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Zaloguj się'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginComponent;