import YeticoLogo from '../../images/logo_yetico_menu.svg';
import { useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';

import PolitykaPrywatnosci from '../../files/Polityka prywatności_Yetico_06.2024_2.pdf';
import RegulaminKonkursu from '../../files/Regulamin konkursu kreatywnego.pdf';
import RegulaminProgramu from '../../files/regulamin programu motywacyjnego.pdf';

function Footer() {

    const ScrollToSection = () => {
        const { hash } = useLocation();
      
        useEffect(() => {
          if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }
        }, [hash]);
      
        return null;
    };

    return (
      <footer className="footer">
        <ScrollToSection />
        <div className="footer__inner">
            <div className='footer__logo_wrapper'>
                <img src={YeticoLogo} alt="Yetico" />
            </div>
            <div className='footer__wrapper'>
                <div className='footer__column1'>
                    <NavLink to="/home#contact" className='footer__link'>Kontakt</NavLink>
                    <a className='footer__link' href={RegulaminKonkursu}>Regulamin konkursu</a>
                    <a className='footer__link' href={RegulaminProgramu}>Regulamin programu</a>
                    <a className='footer__link' href={PolitykaPrywatnosci}>Polityka Prywatności</a>
                </div>
                <div className='footer__column2'>
                    <div className='footer__column_wrapper'>
                        <p className='footer__copyrights'>© 2024. YETICO S.A. WSZELKIE PRAWA ZASTRZEŻONE</p>
                        <p className='footer__website'>www.yetico.com</p>
                    </div>
                    <p className='footer__bottom_text'>Organizator akcji: <br className='footer__breakline' />Agencja Look Ad Buczyk sp. k.</p>
                </div>
            </div>
        </div>
      </footer>
    );
  }
  
export default Footer;