import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import CompanyData from '../account/components/CompanyData';
import { API_PATH } from '../../../Variables';
import { useNavigate } from 'react-router-dom';
import UploadIco from '../../images/upload_ico.svg';
import PageTitle from '../../components/main/PageTitle';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

Modal.setAppElement('#root');

function Home() {
    const [file, setFile] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalErrorIsOpen, setModalErrorIsOpen] = useState(false);
    const [modalFileSizeErrorIsOpen, setModalFileSizeErrorIsOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const userId = user?.id;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFile.size > 50 * 1024 * 1024) { // 50MB in bytes
            setModalFileSizeErrorIsOpen(true);
            setFile(null);
        } else {
            setFile(selectedFile);
        }
    };

    const handleFileRemove = () => {
        setFile(null);
    };

    const handleSubmit = async (event) => {
        if (!file) {
            setModalErrorIsOpen(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);

        try {
            setLoading(true);
            await axios.post(`${API_PATH}/upload`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setLoading(false);
            setModalIsOpen(true);
        } catch (error) {
            setLoading(false);
            alert('Coś poszło nie tak.. Spróbuj ponownie później.');
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        navigate('/competition');
    };

    const closeModalError = () => {
        setModalErrorIsOpen(false);
    }

    const closeModalFileSizeError = () => {
        setModalFileSizeErrorIsOpen(false);
    }

    const initialValues = {
        accept2: false
    }

    const validationSchema = Yup.object({
        accept2: Yup.bool().oneOf([true], 'Wymagana zgoda'),
    });

    return (
        <>
            <Header />
            <PageTitle title="Zgłoszenie konkursowe" />
            <section className="application">
                <div className='application__inner'>
                    <h1 className='application__title'>Zgłoszenie konkursowe</h1>
                    <div className='application__wrapper'>
                        <div className='application__col1'>
                            {/* <form onSubmit={handleSubmit} className='application__form'> */}
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                >
                                {({ values, setFieldValue, handleChange, isSubmitting, errors }) => (
                                    <Form className='application__form'>
                                <input 
                                    type="file" 
                                    id='file'
                                    accept=".mp4" 
                                    onChange={handleFileChange} 
                                    className='application__file-input'
                                />
                                <label htmlFor="file" className='application__file-label'>
                                    {file ? (
                                        <div className='application__file-info'>
                                            <span>{file.name}</span>
                                            <button type="button" onClick={handleFileRemove} className='application__file-remove'>✖</button>
                                        </div>
                                    ) : (
                                        <>
                                            <img src={UploadIco} alt='upload' className='application__upload_ico' />
                                            <div className='application__label_text'>wgraj plik</div>
                                            <div className='application__label_text'>max. 50 MB</div>
                                            <div className='application__label_text'>(dopuszczalne formaty: mp4)</div>
                                        </>
                                    )}
                                </label>
                                <div className='register__checkbox_wrapper'>
                                    <Field type='checkbox' name='accept2' id='accept2' />
                                    <label htmlFor='accept2'>
                                    ** Zapoznałem się z <a className='register__link' href='/'>Regulaminem </a>konkursu dodatkowego dla uczestników programu motywacyjnego "Hi Yetico", w tym przede wszystkim z klauzulą informacyjną dotyczącą przetwarzania danych osobowych.
                                    </label>
                                    <ErrorMessage name='accept2' component='div' className='register__error' />
                                </div>
                                <button type="submit" disabled={isSubmitting} className='application__submit'>{loading ? <div className="loader"></div> : 'Wysyłam zgłoszenie'}</button>
                            {/* </form> */}
                            </Form>
                                )}
                            </Formik>
                        </div>
                        <div className='application__col2'>
                            <CompanyData />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Notification"
                className="Modal"
                overlayClassName="Overlay"
            >
                <p><strong>Udało się!</strong></p>
                <p>Zgłoszenie zostało wysłane.</p>
                <p>Zwycięzców wyłonimy<br />4 sierpnia 2024 r.</p>
                <p>Ze zwycięzcami konkursu skontaktujemy się mailowo lub telefonicznie.</p>
                <button onClick={closeModal}>ok</button>
            </Modal>
            <Modal
                isOpen={modalErrorIsOpen}
                onRequestClose={closeModalError}
                contentLabel="Notification"
                className="Modal"
                overlayClassName="Overlay"
            >
                <p><strong>Proszę dodać plik przed wysłaniem!</strong></p>
                <button onClick={closeModalError}>ok</button>
            </Modal>
            <Modal
                isOpen={modalFileSizeErrorIsOpen}
                onRequestClose={closeModalFileSizeError}
                contentLabel="Notification"
                className="Modal"
                overlayClassName="Overlay"
            >
                <p><strong>Plik jest zbyt duży, maksymalny rozmiar to 50MB!</strong></p>
                <button onClick={closeModalFileSizeError}>ok</button>
            </Modal>
        </>
    );
}

export default Home;