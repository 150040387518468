import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Image1 from '../../../images/70_7_ kuchenka2.png'; // Placeholder image
import EkspresImg from '../../../images/70_6_ ekspres2.png';
import Karta150Img from '../../../images/30_12_karta150.png';
import KuchenkaImg from '../../../images/70_7_ kuchenka2.png';
import Karta450Img from '../../../images/70_8_ karta3502.png';
import getImagePath from '../../../../utils/getImagePath';
import { API_PATH } from '../../../../Variables';

function HistoryOfOrders() {
    const userJson = localStorage.getItem('user');
    const user = JSON.parse(userJson);
    const [orders, setOrders] = useState([]);

    const getGlobalImagePath = (imgName, defaultImage) => {
        if (imgName === 'Ekspres DELONGHI Dolce Gusto Mini Me') {
            return EkspresImg;
        } if (imgName === 'Karta przedpłacona 150 zł') {
            return Karta150Img;
        } if (imgName === 'Kuchenka mikrofalowa AMICA AMGF20M1W') {
            return KuchenkaImg;
        } if (imgName === 'Karta przedpłacona Pluxee 350 zł') {
            return Karta450Img;
        } else {
            return defaultImage;
        }
    };

    const fetchAwardData = async (awardId, token) => {
        try {
            const response = await axios.get(`${API_PATH}/awards/${awardId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching award data:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchOrders = async () => {
            const token = localStorage.getItem('token');
            const userId = user.id;

            try {
                const response = await axios.get(`${API_PATH}/orders/user/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const ordersWithAwards = await Promise.all(response.data.map(async (order) => {
                    const awardData = await fetchAwardData(order.award_id, token);
                    const imagePath = awardData ? getImagePath(awardData.img_name) : Image1;
                    const awardImage = awardData ? getGlobalImagePath(awardData.name, imagePath) : Image1;
                    return {
                        ...order,
                        awardName: awardData ? awardData.name : 'Unknown Award',
                        awardImage: awardImage,
                    };
                }));

                setOrders(ordersWithAwards);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div className="account__content_data">
            <p className='account__content_title'>Historia zamówień</p>
            <div className="historyOrders">
                {orders.map((order) => (
                    <div key={order.id} className="historyOrders__order">
                        <img src={order.awardImage} alt={order.awardName} />
                        <div className="historyOrders__desc">
                            <p className="historyOrders__name">{order.awardName}</p>
                            <p className="historyOrders__quantity">ilość: {order.quantity} szt</p>
                            <p className="historyOrders__name_data">Dane do wysyłki:</p>
                            <p className="historyOrders__data">{order.name}</p>
                            <p className="historyOrders__data">{order.last_name}</p>
                            <p className="historyOrders__data">{user.phone_number}</p>
                            <p className="historyOrders__data">{user.email}</p>
                            <p className="historyOrders__data">{order.company_name}</p>
                            <p className="historyOrders__data">{order.street} {order.street_number}</p>
                            <p className="historyOrders__data">{order.zip_code}</p>
                            <p className="historyOrders__data">{order.city}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HistoryOfOrders;