import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PageTitle from '../../../components/main/PageTitle';
import FlowerImg from '../../../images/flower-bg2.png';
import { useNavigate } from 'react-router-dom';
import { API_PATH } from '../../../../Variables';

function Main() {
    const navigate = useNavigate();
    const [quizPassed, setQuizPassed] = useState(null);
    const [quizPassed2, setQuizPassed2] = useState(null);
    const [quizPassed3, setQuizPassed3] = useState(null);
    const [quizPassed4, setQuizPassed4] = useState(null);

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem('user')).id;

        axios.get(`${API_PATH}/user/${userId}/quiz-1-status`)
            .then(response => {
                const { quiz_1_status } = response.data;
                setQuizPassed(quiz_1_status);
            })
            .catch(error => {
                console.error('There was an error fetching the quiz data!', error);
            });
    }, []);

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem('user')).id;

        axios.get(`${API_PATH}/user/${userId}/quiz-2-status`)
            .then(response => {
                const { quiz_2_status } = response.data;
                setQuizPassed2(quiz_2_status);
            })
            .catch(error => {
                console.error('There was an error fetching the quiz data!', error);
            });
    }, []);

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem('user')).id;

        axios.get(`${API_PATH}/user/${userId}/quiz-3-status`)
            .then(response => {
                const { quiz_3_status } = response.data;
                setQuizPassed3(quiz_3_status);
            })
            .catch(error => {
                console.error('There was an error fetching the quiz data!', error);
            });
    }, []);

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem('user')).id;

        axios.get(`${API_PATH}/user/${userId}/quiz-4-status`)
            .then(response => {
                const { quiz_4_status } = response.data;
                setQuizPassed4(quiz_4_status);
            })
            .catch(error => {
                console.error('There was an error fetching the quiz data!', error);
            });
    }, []);

    return (
        <section className="quizzes">
            <PageTitle title="Quizy" />
            <div className="quizzes__inner">
                <h1 className='quizzes__title'>Quizy</h1>
                <p className='quizzes__subtitle'>Bierz udział w quizach i zdobywaj dodatkowe punkty lub nagrody rzeczowe. Pierwszych 50 uczestników, którzy wezmą udział w quizie 1 oraz w quizie 2 i odpowiedzą na wszystkie pytania poprawnie, otrzymają dodatkową nagrodę.</p>
                <div className='quizzes__wrapper'>
                    <div className='quizzes__container'>
                        <div className='quizzes__square'>
                            <p className='quizzes__container_quiz_title quizzes__container_quiz_title--active'>QUIZ 1</p>
                            <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                            <p className='quizzes__container_points_award'>
                                3 pkt
                                <span className='quizzes__container_plus'>+<br /></span>
                                <span className='quizzes__container_extra_award'>PIŁKA NOŻNA</span>
                                <span className='quizzes__container_extra_award-text'>z limitowanej<br />edycji Yetico</span>
                            </p>
                            <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                        </div>
                        <p className='quizzes__availability'>
                            {quizPassed === 1 ? <strong>Quiz zakończony</strong> : 'Quiz dostępny od 08.07.2024'}
                            </p>
                            {quizPassed !== 1 ? (
                                <button 
                                className='quizzes__button' 
                                onClick={() => navigate('/quizzes/quiz1')}
                                disabled={quizPassed === 1}
                                >
                                    Biorę udział
                                </button>
                            ) : (
                                null
                        )}
                    </div>
                    <div className='quizzes__container'>
                        <div className='quizzes__square'>
                            <p className='quizzes__container_quiz_title quizzes__container_quiz_title--active'>QUIZ 2</p>
                            <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                            <p className='quizzes__container_points_award'>
                                3 pkt
                                <span className='quizzes__container_plus'>+<br /></span>
                                <span className='quizzes__container_extra_award'>Zestaw gadżetów</span>
                                <span className='quizzes__container_extra_award-text'>od Yetico</span>
                            </p>
                            <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                        </div>
                        <p className='quizzes__availability'>
                            {quizPassed2 === 1 ? <strong>Quiz zakończony</strong> : 'Quiz dostępny od 29.07.2024'}
                        </p>
                        {quizPassed2 !== 1 ? (
                            <button 
                            className='quizzes__button' 
                            onClick={() => navigate('/quizzes/quiz2')}
                            disabled={quizPassed2 === 1}
                            >
                                Biorę udział
                            </button>
                        ) : (
                            null
                        )}
                    </div>
                    <div className='quizzes__container'>
                    <div className='quizzes__square'>
                            <p className='quizzes__container_quiz_title quizzes__container_quiz_title--active'>QUIZ 3</p>
                            <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                            <p className='quizzes__container_points_award'>
                            5 pkt
                            </p>
                            <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                        </div>
                        <p className='quizzes__availability'>
                            {quizPassed3 === 1 ? <strong>Quiz zakończony</strong> : 'Quiz dostępny od 19.08.2024'}
                        </p>
                        {quizPassed3 !== 1 ? (
                            <button 
                            className='quizzes__button' 
                            onClick={() => navigate('/quizzes/quiz3')}
                            disabled={quizPassed3 === 1}
                            >
                                Biorę udział
                            </button>
                        ) : (
                            null
                        )}
                    </div>
                    
                    <div className='quizzes__container'>
                    <div className='quizzes__square'>
                            <p className='quizzes__container_quiz_title quizzes__container_quiz_title--active'>QUIZ 4</p>
                            <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                            <p className='quizzes__container_points_award'>
                            5 pkt
                            </p>
                            <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                        </div>
                        <p className='quizzes__availability'>              
                            {quizPassed4 === 1 ? <strong>Quiz zakończony</strong> : 'Quiz dostępny od 09.09.2024'}
                        </p>
                        {quizPassed4 !== 1 ? (
                            <button 
                            className='quizzes__button' 
                            onClick={() => navigate('/quizzes/quiz4')}
                            disabled={quizPassed4 === 1}
                            >
                                Biorę udział
                            </button>
                        ) : (
                            null
                        )}
                    </div>
                    <div className='quizzes__container'>
                        <div className='quizzes__square'>
                            <p className='quizzes__container_quiz_title'>QUIZ 5</p>
                            <p className='quizzes__container_win_text'>DO WYGRANIA</p>
                            <p className='quizzes__container_points_award'>
                                5 pkt
                            </p>
                            <img src={FlowerImg} alt="Flower" className='quizzes__flower_img' />
                        </div>
                        <p className='quizzes__inaccessible'>Quiz dostępny od 30.09.2024</p> 
                        <button disabled className='quizzes__button'>Biorę udział</button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Main;