import React, { useState } from 'react';
import axios from 'axios';
import Nav from '../../components/Nav';
import { Button, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import { API_PATH } from '../../../../Variables';

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem('token');

  const handleFetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_PATH}/export-users`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
  
      // Wyciągnięcie nazwy pliku z nagłówków odpowiedzi, jeśli jest dostępna
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'users.csv'; // Domyślna nazwa pliku
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
  
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Błąd podczas pobierania użytkowników:', error);
    } finally {
      setIsLoading(false);
    }
  };  

  // Event handler for selecting a file
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Event handler for importing users
  const handleImportUsers = async () => {
    if (!selectedFile) {
      setErrorMessage('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    setImportLoading(true);
    try {
      await axios.post(`${API_PATH}/import-users`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        },
      });
      setSuccessMessage('Plik został zaimportowany pomyślnie!');
      setSelectedFile(null);
    } catch (error) {
      setErrorMessage('Coś poszło nie tak: ' + error.message);
      console.error('Coś poszło nie tak: ', error);
    } finally {
      setImportLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <>
      <Nav />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="top"
        height="100vh"
        gap={2}
        padding={4}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleFetchUsers}
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          Pobierz użytkowników (.csv)
        </Button>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="upload-file"
        />
        <label htmlFor="upload-file">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            disabled={importLoading}
            endIcon={importLoading ? <CircularProgress size={20} /> : null}
          >
            Wybierz plik (.csv)
          </Button>
        </label>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleImportUsers}
          disabled={!selectedFile || importLoading}
          endIcon={importLoading ? <CircularProgress size={20} /> : null}
        >
          Importuj użytkowników (.csv)
        </Button>
        {successMessage && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          </Snackbar>
        )}
        {errorMessage && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>
  );
}

export default Home;