import React from 'react';
import { NavLink } from 'react-router-dom';

const ScrollToTopNavLink = ({ to, children, ...props }) => {
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <NavLink to={to} onClick={handleClick} {...props}>
            {children}
        </NavLink>
    );
};

export default ScrollToTopNavLink;